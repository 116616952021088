import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { UserAvatar } from '@components/Avatar/UserAvatar';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useravatar"
    }}>{`UserAvatar`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={UserAvatar} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<UserAvatar\n  user={{\n    name: \'Ana Smith\',\n  }}\n  size=\"large\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UserAvatar user={{
        name: 'Ana Smith'
      }} size="large" mdxType="UserAvatar" />
    </Playground>
    <h2 {...{
      "id": "with-gender"
    }}>{`With gender`}</h2>
    <Playground __position={2} __code={'<View style={{ flexDirection: \'row\' }}>\n  <UserAvatar\n    user={{\n      name: \'Ana Smith\',\n      gender: {\n        name: \'female\',\n      },\n    }}\n    size=\"large\"\n  />\n  <UserAvatar\n    user={{\n      name: \'Toma Popescu\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    size=\"large\"\n  />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
      <UserAvatar user={{
          name: 'Ana Smith',
          gender: {
            name: 'female'
          }
        }} size="large" mdxType="UserAvatar" />
      <UserAvatar user={{
          name: 'Toma Popescu',
          gender: {
            name: 'male'
          }
        }} size="large" mdxType="UserAvatar" />
  </View>
    </Playground>
    <h2 {...{
      "id": "size-variations"
    }}>{`Size variations`}</h2>
    <Playground __position={3} __code={'<View style={{ flexDirection: \'row\' }}>\n  <UserAvatar\n    user={{\n      name: \'Toma Popescu\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    size=\"large\"\n  />\n  <UserAvatar\n    user={{\n      name: \'Toma Popescu\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    size=\"medium\"\n  />\n  <UserAvatar\n    user={{\n      name: \'Toma Popescu\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    size=\"small\"\n  />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <UserAvatar user={{
          name: 'Toma Popescu',
          gender: {
            name: 'male'
          }
        }} size="large" mdxType="UserAvatar" />
    <UserAvatar user={{
          name: 'Toma Popescu',
          gender: {
            name: 'male'
          }
        }} size="medium" mdxType="UserAvatar" />
    <UserAvatar user={{
          name: 'Toma Popescu',
          gender: {
            name: 'male'
          }
        }} size="small" mdxType="UserAvatar" />
  </View>
    </Playground>
    <h2 {...{
      "id": "rounded"
    }}>{`Rounded`}</h2>
    <Playground __position={4} __code={'<View style={{ flexDirection: \'row\' }}>\n  <UserAvatar\n    user={{\n      name: \'Toma Popescu\',\n      gender: {\n        name: \'male\',\n      },\n    }}\n    rounded\n  />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <UserAvatar user={{
          name: 'Toma Popescu',
          gender: {
            name: 'male'
          }
        }} rounded mdxType="UserAvatar" />
  </View>
    </Playground>
    <h2 {...{
      "id": "action"
    }}>{`Action`}</h2>
    <Playground __position={5} __code={'<UserAvatar\n  user={{\n    name: \'Toma Popescu\',\n    gender: {\n      name: \'male\',\n    },\n  }}\n  size=\"xxLarge\"\n  onPress={() => {\n    console.info(\'On avatar press\')\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      UserAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UserAvatar user={{
        name: 'Toma Popescu',
        gender: {
          name: 'male'
        }
      }} size="xxLarge" onPress={() => {
        console.info('On avatar press');
      }} mdxType="UserAvatar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      